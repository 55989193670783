<template>
  <div v-if="content || hasContent" class="tooltip-wrapper">
    <div
      v-if="viewport.isGreaterOrEquals('md')"
      class="d-none d-md-block position-relative"
      @mouseenter="toggleTooltip"
      @mouseleave="toggleTooltip"
      @keydown.enter.exact.prevent="toggleTooltip"
      @keydown.esc.exact.prevent="toggleTooltip"
    >
      <div ref="activatorRef" class="activator" tabindex="0" @blur="closeTooltip">
        <slot v-if="hasActivator" name="activator" />
        <component :is="icon || IconInfo" v-else lg class="icon" />
      </div>

      <div
        v-if="state.tooltipActive || state.top === null || state.left === null"
        ref="tooltipRef"
        data-id="molecule-tooltip-popup"
        class="tooltip-box"
        :class="{
          visible: state.tooltipActive,
          rendering: state.top === null || state.left === null,
          left: state.position === MoleculeTooltipPosition.LEFT,
          top: state.position === MoleculeTooltipPosition.TOP,
          right: state.position === MoleculeTooltipPosition.RIGHT,
          'dark-small': darkSmall,
          'p-16': !darkSmall,
        }"
        :style="{ top: `${state.top}px`, left: `${state.left}px` }"
      >
        <span v-if="!darkSmall" class="arrow" :style="{ left: state.arrowLeft }" />
        <slot v-if="hasContent" name="content" />

        <!-- eslint-disable vue/no-v-html -->
        <div v-else v-html="description" />
      </div>
    </div>

    <molecule-modal v-else-if="!onlyDesktop" use-only-mobile-version>
      <template #activator>
        <slot v-if="hasActivator" name="activator" />
        <component :is="icon || IconInfo" v-else lg class="icon" />
      </template>

      <template #content>
        <div class="pb-48 px-24 px-sm-48">
          <slot v-if="hasContent" name="content" />
        </div>
      </template>
    </molecule-modal>
    <div v-else>
      <slot v-if="hasActivator" name="activator" />
      <component :is="icon || IconInfo" v-else lg class="icon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconInfo from '@web/packeta-ui-styleguide/icons/status/icon-info.svg'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, useSlots } from 'vue'
import { MoleculeTooltipPosition } from '~common/enums'
import type { MoleculeTooltipProps } from '~common/types'
import MoleculeModal from '~components/molecules/MoleculeModal.vue'

const props = defineProps<MoleculeTooltipProps>()
const slots = useSlots()
const viewport = useViewport()

const state = reactive({
  position: props.defaultPosition,
  tooltipActive: false,
  top: null as number | null,
  left: null as number | null,
  arrowTop: undefined as string | undefined,
  arrowLeft: undefined as string | undefined,
  activatorWidth: null as number | null,
  activatorHeight: null as number | null,
  tooltipWidth: null as number | null,
  tooltipHeight: null as number | null,
})
const activatorRef = ref<HTMLElement | null>(null)
const tooltipRef = ref<HTMLElement | null>(null)

// Computed properties
const content = computed(() => {
  return typeof props.description === 'object' && Object.keys(props.description).length > 0
})

const hasActivator = computed(() => {
  return !!slots.activator
})

const hasContent = computed(() => {
  return !!slots.content
})

// Methods
const toggleTooltip = () => {
  state.tooltipActive = !state.tooltipActive
}

const closeTooltip = () => {
  state.tooltipActive = false
}

const renderTooltip = () => {
  const activator = activatorRef.value
  const tooltip = tooltipRef.value
  if (!activator || !tooltip) {
    return
  }

  state.activatorWidth = activator.clientWidth
  state.activatorHeight = activator.clientHeight
  state.tooltipWidth = tooltip.clientWidth
  state.tooltipHeight = tooltip.clientHeight
}

const tooltipPosition = () => {
  const activator = activatorRef.value
  if (
    !activator ||
    !state.activatorWidth ||
    !state.activatorHeight ||
    !state.tooltipHeight ||
    !state.tooltipWidth
  ) {
    return
  }

  const { left, right } = activator.getBoundingClientRect()
  const { innerWidth } = window

  if (state.position === MoleculeTooltipPosition.LEFT && left - state.tooltipWidth < 0) {
    state.position = MoleculeTooltipPosition.BOTTOM
  } else if (
    state.position === MoleculeTooltipPosition.RIGHT &&
    right + state.tooltipWidth > innerWidth
  ) {
    state.position = MoleculeTooltipPosition.BOTTOM
  }

  const defaultLeft = -(((props.darkSmall ? state.tooltipWidth : 300) - state.activatorWidth) / 2)
  const defaultWidthDiff = state.tooltipWidth / 2 - state.activatorWidth / 2
  const defaultArrowPosition = state.tooltipWidth / 2 - 8

  switch (state.position) {
    case MoleculeTooltipPosition.LEFT:
      state.top =
        state.activatorHeight === state.tooltipHeight
          ? 0
          : state.activatorHeight >= state.tooltipHeight
            ? (state.activatorHeight - state.tooltipHeight) / 2
            : -(state.tooltipHeight - state.activatorHeight) / 2
      state.left = -state.tooltipWidth - (props.nudge || 0)
      break
    case MoleculeTooltipPosition.TOP:
      state.top = -state.tooltipHeight - (props.nudge || 0)
      state.left = defaultLeft
      break
    case MoleculeTooltipPosition.RIGHT:
      state.top =
        state.activatorHeight === state.tooltipHeight
          ? 0
          : state.activatorHeight >= state.tooltipHeight
            ? (state.activatorHeight - state.tooltipHeight) / 2
            : -(state.tooltipHeight - state.activatorHeight) / 2
      state.left = state.activatorWidth + (props.nudge || 0)
      break
    default:
      if (right + defaultWidthDiff > innerWidth) {
        state.left = defaultLeft - (right + defaultWidthDiff - innerWidth) - 25
        state.arrowLeft = `${defaultArrowPosition + (defaultLeft - (defaultLeft - (right + defaultWidthDiff - innerWidth) - 25))}px`
      } else if (defaultWidthDiff >= left) {
        state.left = -left
        state.arrowLeft = `${left + state.activatorWidth / 2}px`
      } else {
        state.left = defaultLeft
        state.arrowLeft = undefined
      }
      state.top = state.activatorHeight + (props.nudge || 0)
  }
}

// Lifecycle hooks
onMounted(() => {
  nextTick(() => {
    renderTooltip()
    tooltipPosition()
  })

  window.addEventListener('resize', tooltipPosition)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', tooltipPosition)
})
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  width: fit-content;
}

.icon {
  color: $grey-200-disable;
  cursor: pointer;

  &:hover {
    color: $red-300;
  }
}

.activator {
  cursor: help;

  @include focus-visible;

  &:focus,
  &:focus-visible {
    .icon {
      color: $base-red;
    }
  }
}

.tooltip-box {
  position: absolute;
  width: 300px;
  padding: $space-16 12px;
  background-color: $base-white;
  z-index: 1;
  opacity: 1;

  &.dark-small {
    width: fit-content;
    max-width: 300px;
    min-width: max-content;
    padding: $space-8 12px;
    background-color: $base-black;
    color: $base-white;
    box-shadow: 8px 8px 32px 0 #00000040;
  }

  &.rendering {
    position: fixed;
    height: fit-content;
    top: 0;
    left: 0;
    opacity: 0;
  }

  @include form-box-shadow;

  :deep(p) {
    margin-bottom: 0;
  }
}

.arrow {
  position: absolute;
  top: -$space-8;
  left: calc(50% - #{$space-8});
  width: $space-16;
  height: $space-16;
  border-top-width: 1px;
  border-left-width: 1px;
  background-color: $base-white;
  rotate: 45deg;
}

.tooltip-box.top {
  .arrow {
    top: calc(100% - #{$space-8});
    left: calc(50% - #{$space-8});
  }
}

.tooltip-box.right {
  .arrow {
    top: calc(50% - #{$space-8});
    left: -$space-8;
  }
}

.tooltip-box.left {
  .arrow {
    top: calc(50% - #{$space-8});
    left: calc(100% - #{$space-8});
  }
}
</style>
